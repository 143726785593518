
import Vue from 'vue';
import { parse } from 'papaparse';
import validationRules from '@/validation-rules';
import { eModeType } from '@/enums';
import { endpoints, getEmptyTeam, MANAGED_ACCOUNTS_MAX, TEAMS_MAX, TEAM_MEMBERS_MAX } from '@/constants';
import { format } from 'date-fns';
import { storeMapPropUpdate } from '@/store';
import { collection, deleteDoc, deleteField, doc, DocumentData, DocumentReference, getDoc, getDocs, setDoc, Timestamp, updateDoc, writeBatch } from 'firebase/firestore';
import { signInWithEmailAndPassword, User } from 'firebase/auth';
import { signOutAccount, onUserSignIn } from '@/util-functions/auth-utils';
import { currFirestore, fbAuth, standardApiFetch, isProductionEnv } from '@/util-functions/initialization-utils';
import { t } from '@/util-functions/language-utils';
import { showLoading, hideLoading } from '@/util-functions/loading-utils';
import { showError, generateId } from '@/util-functions/misc-firestore-utils';
import { showSuccess, showConfirmation, showNotice, showSnackBar } from '@/util-functions/notice-utils';
import { isPremiumActivated, copyText, getUserDoc, updateUserLocal } from '@/util-functions/user-utils';

const maxTeamsPerPage = 5;

export default Vue.extend({
  data(): {
    teams: { [teamId: string]: Team };
    allTeamMembersMap: { [userId: string]: TeamMemberForTeamsPage };
    showContent: Record<string, boolean>;
    currFullEmail: string;
    currEmailDomain: string;
    rules: typeof validationRules;
    deletedTeamsIds: string[];
    newAccountEmail: string;
    newAccountPassword: string;
    newAccountDisplayName: string;
    showPassword: boolean;
    showPasswordMap: { [userId: string]: boolean };
    showAccountCreationDialog: boolean;
    showAccountCreationFromCsvDialog: boolean;
    showAssignAccountsFromCsvDialog: boolean;
    showTeamMemberActionsDialog: boolean;
    headers: TableHeaders;
    showAccountActionsDialog: boolean;
    currentAccount: ManagedAccount | null;
    userDataForCurrentManagedUser: PlatformUser | null;
    currentTeamMember: TeamMemberForTeamsPage | null;
    currentTeamMemberTeam: Team | null;
    currentSelectedTeam: Team | null;
    uploadedCreationCsvFile: Blob | null;
    uploadedAssignmentCsvFile: Blob | null;
    formIsDirty: boolean;
    dataTableToggle: boolean;
    tableSearchTerm: string;
    newPassword: string;
    newDisplayName: string;
    newEmail: string;
    isManagedUserFormValid: boolean;
    currPage: number;
  } {
    return {
      teams: { ...this.$store.state.createdTeams },
      allTeamMembersMap: {},
      showContent: {},
      currFullEmail: '',
      currEmailDomain: '',
      rules: validationRules,
      deletedTeamsIds: [],
      newAccountEmail: '',
      newAccountPassword: '',
      newAccountDisplayName: '',
      showPassword: false,
      showPasswordMap: {},
      showAccountCreationDialog: false,
      showAccountCreationFromCsvDialog: false,
      showAssignAccountsFromCsvDialog: false,
      showTeamMemberActionsDialog: false,
      headers: [
        // { text: t?.dateCreated, value: 'dateCreated' },
        { text: t?.email, value: 'email' },
        { text: t?.name, value: 'displayName' },
        { text: t?.teams, sortable: false },
        { text: '', sortable: false },
      ],
      showAccountActionsDialog: false,
      currentAccount: null,
      userDataForCurrentManagedUser: null,
      currentTeamMember: null,
      currentTeamMemberTeam: null,
      currentSelectedTeam: null,
      uploadedCreationCsvFile: null,
      uploadedAssignmentCsvFile: null,
      formIsDirty: false,
      dataTableToggle: false,
      tableSearchTerm: '',
      newPassword: '',
      newDisplayName: '',
      newEmail: '',
      isManagedUserFormValid: false,
      currPage: 1,
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.teamsArray().length / maxTeamsPerPage);
    },
    isAdmin(): boolean {
      return this.$store.state.isAdmin;
    },
    userCreationEnabled(): boolean {
      return this.$store.state.featureFlags.userCreation;
    },
    allModes(): TeamMode[] {
      return (Object.values(this.$store.state.modes) as Mode[])
        .sort((a: Mode, b: Mode) => {
          return a.index - b.index;
        })
        .map((mode: Mode) => {
          return {
            docId: mode.docId,
            linkId: mode.linkId,
            name: mode.name,
            displayName: mode.name,
            type: mode.type as eModeType,
          };
        });
    },
    allNonDefaultThemes(): AestheticTheme[] {
      return (Object.values(this.$store.state.currUserModeGateway.themes || []) as AestheticTheme[]).sort((a: any, b: any) => (a.name || '').localeCompare(b.name || ''));
    },
    isPremiumActivated(): boolean {
      return isPremiumActivated();
    },
    managedAccounts(): ManagedAccount[] {
      return (Object.values(this.$store.state.currUser?.managedAccounts || {}) as ManagedAccount[]).sort((a: ManagedAccount, b: ManagedAccount) =>
        (a.displayName || '').localeCompare(b.displayName || '')
      );
    },
    joinedTeamsForCurrentViewedAccount(): Team[] {
      {
        const teamIds = this.teamsArray().map((team) => team.docId);
        // Only get the teams that also belong to the current logged in users account. From those teams IDs, map them to team objects.
        const teamObjectsForCurrentUSer = this.userDataForCurrentManagedUser
          ? this.userDataForCurrentManagedUser.joinedTeamsRefs.filter((ref) => teamIds.includes(ref.id)).map((ref) => this.teams[ref.id])
          : [];
        // Remove any dupes.
        const teamsIdsForCurrentUser = [...new Set(teamObjectsForCurrentUSer.map((team) => team.docId))];
        const teams = teamsIdsForCurrentUser.map((teamId) => this.teams[teamId]);
        return teams;
      }
    },
  },
  beforeRouteUpdate(to, from, next) {
    this.onRouteChange(next);
  },
  beforeRouteLeave(to, from, next) {
    this.onRouteChange(next);
  },
  created() {
    window.addEventListener('beforeunload', this.beforeWindowUnload);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeWindowUnload);
  },
  mounted() {
    this.onLoad();
  },
  methods: {
    confirmLeave() {
      return window.confirm(t.unsavedChanges);
    },
    confirmStayInDirtyForm() {
      return this.formIsDirty && !this.confirmLeave();
    },
    beforeWindowUnload(e: BeforeUnloadEvent) {
      if (this.confirmStayInDirtyForm()) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    },
    onRouteChange(next: any) {
      hideLoading(true);
      if (this.formIsDirty) {
        next(false);
        showConfirmation(t.unsavedChanges, () => {
          next();
        });
      } else {
        // Navigate to next view
        next();
      }
    },
    onFormInteraction() {
      this.formIsDirty = true;
    },
    copyText(str: string) {
      return copyText(str);
    },
    onLoad() {
      const allTeamMembersMap: { [userId: string]: TeamMemberForTeamsPage } = {};
      const createdTeams: { [teamId: string]: Team } = {};
      showLoading();
      getDocs(collection(currFirestore, getUserDoc().path, 'teams'))
        .then((querySnap) => {
          if (querySnap.size) {
            const promises: Promise<any>[] = [];
            querySnap.forEach(async (docSnap) => {
              const team = docSnap.data() as Team;
              createdTeams[team.docId] = team;
              promises.push(
                getDocs(collection(currFirestore, docSnap.ref.path, 'members'))
                  .then((membersQuerySnap) => {
                    if (membersQuerySnap.size) {
                      membersQuerySnap.forEach((memberDocSnap) => {
                        const teamMember = memberDocSnap.data() as TeamMember;
                        // Either add this a member to allTeamMembersMapMap if they do not exist or just push the new team.
                        if (allTeamMembersMap[teamMember.userId]) {
                          allTeamMembersMap[teamMember.userId].teams.push(team.docId);
                        } else {
                          allTeamMembersMap[teamMember.userId] = {
                            ...teamMember,
                            teams: [team.docId],
                          };
                        }
                      });
                    }
                  })
                  .finally(() => {
                    hideLoading();
                  })
              );
              Promise.all(promises).then(() => {
                this.allTeamMembersMap = allTeamMembersMap;
                this.refreshAccountTable();
              });
            });
            this.$store.commit('createdTeams', createdTeams);
            this.teams = createdTeams;
          } else {
            hideLoading();
          }
        })
        .catch((error) => {
          showError(`Could not get teams:`, error, true);
          hideLoading();
        });
    },
    membersForTeam(teamId: string) {
      return Object.values(this.allTeamMembersMap).filter((member) => member.teams.includes(teamId));
    },
    converTeamIdArrayToTeamNameArray(teamIdArray: string[]): string {
      const ret = teamIdArray.map((id) => this.teams[id].name).join(', ');
      return ret.length ? ret : t.none;
    },
    teamsArray(): Team[] {
      return Object.values(this.teams).sort((a: Team, b: Team) => {
        return (a?.name || '').localeCompare(b?.name || '');
      });
    },
    teamsArrayForCurrentPage(): Team[] {
      const index = (this.currPage - 1) * maxTeamsPerPage;
      return this.teamsArray().slice(index, index + maxTeamsPerPage);
    },
    teamsTheCurrentUserHasNotJoined(): Team[] {
      return Object.values(this.teams).filter((team) => {
        return !this.joinedTeamsForCurrentViewedAccount.map((joinedTeam) => joinedTeam.docId).includes(team.docId);
      });
    },
    showPasswordFor(userId: string) {
      this.showPasswordMap[userId] = !this.showPasswordMap[userId];
      this.$forceUpdate();
      (this.$refs.accountsTable as any).$forceUpdate();
    },
    onManagedTeamMemberActionsClick(member: TeamMember) {
      const account = this.managedAccounts.find((account) => account.userId === member.userId);
      if (account) {
        this.onManagedAccountActionsClick(account);
      }
    },
    onManagedAccountActionsClick(account: ManagedAccount) {
      showLoading();
      this.currentAccount = account;
      this.newPassword = this.currentAccount.password;
      this.newDisplayName = this.currentAccount.displayName;
      this.newEmail = this.currentAccount.email;

      const managedUserId = this.currentAccount?.userId;
      const userDocForManagedUser = doc(currFirestore, 'users', managedUserId);

      getDoc(userDocForManagedUser)
        .then((docSnap) => {
          if (docSnap.exists()) {
            this.userDataForCurrentManagedUser = docSnap.data() as PlatformUser;
          } else {
            this.userDataForCurrentManagedUser = null;
          }
          this.showAccountActionsDialog = true;
          hideLoading(true);
        })
        .catch((error) => {
          hideLoading(true);
          showError(`Could not add the user to the specified team.`, error, true);
        });
    },
    hideActionsDialog() {
      this.currentAccount = null;
      this.showAccountActionsDialog = false;
    },
    onTeamMemberClick(team: Team, member: TeamMemberForTeamsPage) {
      this.showTeamMemberActionsDialog = true;
      this.currentTeamMember = member;
      this.currentTeamMemberTeam = team;
    },
    hideTeamMemberActionsDialog() {
      this.showTeamMemberActionsDialog = false;
      this.currentTeamMember = null;
      this.currentTeamMemberTeam = null;
    },
    onCurrentTeamMemberNameChange(displayName: string) {
      this.onCurrentTeamMemberInfoChange('displayName', displayName);
    },
    onCurrentTeamMemberEmailChange(email: string) {
      this.onCurrentTeamMemberInfoChange('email', email);
    },
    onCurrentTeamMemberInfoChange(field: 'email' | 'displayName', data: string) {
      if (!this.currentTeamMember || !this.currentTeamMemberTeam) {
        return;
      }
      const userId = this.$store.state.userId;
      const teamId = this.currentTeamMemberTeam.docId;
      const memberId = this.currentTeamMember.userId;
      showLoading();
      updateDoc(doc(currFirestore, 'users', userId, 'teams', teamId, 'members', memberId), {
        [field]: data,
        dateUpdated: Timestamp.fromMillis(Date.now()),
      })
        .then(() => {
          showNotice(t.updateSuccessful);
        })
        .catch((error) => {
          showError(`Could not update name.`, error, true);
        })
        .finally(() => {
          hideLoading();
        });
    },
    removeTeamMember() {
      if (!this.currentTeamMember || !this.currentTeamMemberTeam) {
        return;
      }
      const userId = this.$store.state.userId;
      const teamId = this.currentTeamMemberTeam.docId;
      const memberId = this.currentTeamMember.userId;
      showLoading();
      deleteDoc(doc(currFirestore, 'users', userId, 'teams', teamId, 'members', memberId))
        .then(() => {
          delete this.allTeamMembersMap[memberId];
          this.hideTeamMemberActionsDialog();
          showNotice(t.removalSuccessful);
        })
        .catch((error) => {
          showError(`Could not remove the team member.`, error, true);
        })
        .finally(() => {
          hideLoading();
        });
    },
    addManagedUserToSelectedTeam(providedTeam: Team | null = null, providedManagedUser: PlatformUser | null = null, isForSingleManagedUserUpdate = false): Promise<void> {
      const team: Team | null = providedTeam || this.currentSelectedTeam;
      const managedUser: PlatformUser | null = providedManagedUser || this.userDataForCurrentManagedUser;

      if (!managedUser || !team) {
        return Promise.reject('No user or no team provided.');
      }

      if (team.memberCount >= TEAM_MEMBERS_MAX && !this.$store.state?.currUserModeGateway.hasPermanentPremium) {
        showError(t.teamAtMax.supplant([team.name, TEAM_MEMBERS_MAX]));
        return Promise.reject();
      }

      const teamId = team.docId;
      const userId = this.$store.state.userId;

      const teamMember = this.allTeamMembersMap[managedUser.docId];
      if (teamMember) {
        teamMember.teams.push(teamId);
      } else {
        const newMember: TeamMemberForTeamsPage = {
          displayName: managedUser.displayName,
          email: managedUser.email,
          userId: managedUser.docId,
          teams: [teamId],
          dateCreated: managedUser.dateCreated,
          dateUpdated: managedUser.dateUpdated,
        };

        this.allTeamMembersMap[managedUser.docId] = newMember;
      }

      const teamDocRef = doc(currFirestore, 'users', userId, 'teams', teamId);
      // Check if they are already in the team.
      if (managedUser.joinedTeamsRefs.some((ref) => ref.id === teamDocRef.id)) {
        return Promise.reject('They are already in that team.');
      }
      const newJoinedTeamsRefs = [...managedUser.joinedTeamsRefs, teamDocRef];
      const oldWhiteListedEmails = this.teams[teamId].whiteListedEmails;
      const newWhiteListedEmails = !oldWhiteListedEmails.includes(managedUser.email) ? [...this.teams[teamId].whiteListedEmails, managedUser.email] : oldWhiteListedEmails;
      return this.addOrRemoveManagedUserFromTeam(managedUser, teamId, newWhiteListedEmails, newJoinedTeamsRefs, false, isForSingleManagedUserUpdate);
    },
    removeManagedUserFromSelectedTeam(teamId: string, isForSingleManagedUserUpdate = false): Promise<void> {
      showLoading();

      if (!this.userDataForCurrentManagedUser) {
        hideLoading();
        return Promise.reject('No user provided to add or remove from the team.');
      }

      const managedUser: PlatformUser = this.userDataForCurrentManagedUser;

      // Remove the team from the array in the all team members map.
      this.allTeamMembersMap[managedUser.docId].teams = this.allTeamMembersMap[managedUser.docId].teams.filter((team) => team !== teamId);
      let newJoinedTeamsRefs = [...managedUser.joinedTeamsRefs];
      newJoinedTeamsRefs = newJoinedTeamsRefs.filter((ref) => !ref.path.includes(teamId));
      const newWhiteListedEmails = this.teams[teamId].whiteListedEmails.filter((email) => email !== managedUser.email);
      return this.addOrRemoveManagedUserFromTeam(managedUser, teamId, newWhiteListedEmails, newJoinedTeamsRefs, true, isForSingleManagedUserUpdate);
    },
    addOrRemoveManagedUserFromTeam(
      providedManagedUser: PlatformUser | null,
      teamId: string,
      whiteListedEmails: string[],
      joinedTeamsRefs: DocumentReference<DocumentData>[],
      isForRemoval: boolean,
      isForSingleManagedUserUpdate: boolean
    ): Promise<void> {
      const managedUser: PlatformUser | null = providedManagedUser || this.userDataForCurrentManagedUser;

      if (!managedUser) {
        hideLoading();
        return Promise.reject('No user provided to add or remove from the team.');
      }

      whiteListedEmails = [...new Set(whiteListedEmails)];

      const userDocForManagedUser = doc(currFirestore, 'users', managedUser.docId);
      const batch = writeBatch(currFirestore);
      const teamDoc = doc(currFirestore, getUserDoc().path, 'teams', teamId);

      if (!isForSingleManagedUserUpdate) {
        // White list the new team member;
        batch.update(teamDoc, {
          whiteListedEmails,
        });
      }

      const newTeamMember = {
        userId: managedUser.docId,
        displayName: managedUser.displayName,
        email: managedUser.email,
        dateCreated: Timestamp.fromMillis(Date.now()),
      };

      const team: Team = this.teams[teamId];

      // Add the new team member to the members collection if they are joining or delete them if they are leaving.
      const teamMemberDoc = doc(currFirestore, teamDoc.path, 'members', managedUser.docId);
      if (isForRemoval) {
        // Clean up the team members gateway doc.
        // const currTeamsTeamOwnerGatewayRef = doc(currFirestore, 'publicUserModeGateways', team.teamOwnerId);
        // const gatewayDocForManagedUserUpdate: Partial<PublicUserModeGateway> = {                 
        //   dateUpdated: Timestamp.fromMillis(Date.now()),
        // };
        // if (wrapperModeOwnerId === store.state.userId) {
        //   gatewayDocForManagedUserUpdate.wrapperModeOwnerId = '';          
        // }
        // if (activeModeOwnerId === store.state.userId) {
        //   gatewayDocForManagedUserUpdate.activeModeOwnerId = '';          
        // }
        // if (assignedTeamThemeId === team.assignedThemeId) {
        //   gatewayDocForManagedUserUpdate.assignedTeamThemeId = '';          
        // }
        // if (teamOwnerGatewayRef === currTeamsTeamOwnerGatewayRef.path) {
        //   gatewayDocForManagedUserUpdate.teamOwnerGatewayRef = null;          
        // }                                
        // batch.update(gatewayDocForManagedUser, gatewayDocForManagedUserUpdate);
        batch.delete(teamMemberDoc);
      } else {
        batch.set(teamMemberDoc, newTeamMember);
      }

      const userDocForManagedUserUpdate: Partial<PlatformUser> = {
        joinedTeamsRefs,
        dateUpdated: Timestamp.fromMillis(Date.now()),
      };

      // Inform the user account for the new team member that they are in a new team or remove the reference to the team they left.
      batch.update(userDocForManagedUser, userDocForManagedUserUpdate);

      if (team.assignedThemeId) {
        const gatewayDocForManagedUser = doc(currFirestore, 'publicUserModeGateways', managedUser.docId);
        const teamOwnerGatewayRef = doc(currFirestore, 'publicUserModeGateways', team.teamOwnerId);
        const gatewayDocForManagedUserUpdate: Partial<PublicUserModeGateway> = {
          teamOwnerGatewayRef,
          assignedTeamThemeId: team.assignedThemeId,
          dateUpdated: Timestamp.fromMillis(Date.now()),
        };
        batch.update(gatewayDocForManagedUser, gatewayDocForManagedUserUpdate);
      }

      return batch
        .commit()
        .then(() => {
          managedUser.joinedTeamsRefs = joinedTeamsRefs;
          if (!isForSingleManagedUserUpdate) {
            storeMapPropUpdate('createdTeams', teamId, {
              whiteListedEmails,
            });
            this.teams[teamId].whiteListedEmails = whiteListedEmails;
            setTimeout(() => {
              this.dataTableToggle = true;
            }, 100);
          }
          hideLoading(true);
          showSuccess(t.updateSuccessful);
        })
        .catch((error) => {
          hideLoading(true);
          showError(`Could not add/remove the user to the specified team.`, error, true);
        });
    },
    disconnectAccount() {
      // To disconnect a managed account we simply delete the managedUser doc from userManagers/{docId}/managedUsers/{docId}.
      showConfirmation(t?.disconnectAccountConfirm, () => {
        const currUser = fbAuth.currentUser;

        if (!currUser) {
          showError(`No logged in user`, null, true);
          return;
        }

        showLoading();

        const managedUserId = this.currentAccount?.userId || '';
        const userId = this.$store.state.userId;
        const batch = writeBatch(currFirestore);
        const managedUserDoc = doc(currFirestore, 'userManagers', userId, 'managedUsers', managedUserId);
        const managedUsersOwnUserDoc = doc(currFirestore, 'users', managedUserId);
        const managedUserGatewayDoc = doc(currFirestore, 'publicUserModeGateways', managedUserId);
        const userDoc = doc(currFirestore, 'users', userId);
        batch.update(managedUserGatewayDoc, {
          teamOwnerGatewayRef: null,
          assignedTeamThemeId: '',
          dateUpdated: Timestamp.fromMillis(Date.now()),
        });
        batch.update(managedUsersOwnUserDoc, {
          wasCreatedBy: '',
        });
        batch.delete(managedUserDoc);
        batch.update(userDoc, {
          [`managedAccounts.${managedUserId}`]: deleteField(),
        });
        batch
          .commit()
          .then(() => {
            const newManagedAccounts = { ...this.$store.state.currUser.managedAccounts };
            delete newManagedAccounts[managedUserId];
            updateUserLocal({
              managedAccounts: newManagedAccounts,
            });
            showSuccess(t.accountSuccessfullyDisconnected);
            this.showAccountActionsDialog = false;
            hideLoading(true);
          })
          .catch((error) => {
            showError(`Could not get user token to make request`, error, true);
            hideLoading(true);
          });
      });
    },
    createAccount() {
      const currUser = fbAuth.currentUser;

      if (!currUser) {
        showError(`No logged in user`, null, true);
        return;
      }

      if (!(this.$refs.accountForm as any).validate()) {
        return;
      }

      showLoading();

      currUser
        .getIdToken(/* forceRefresh */ true)
        .then((idToken) => {
          standardApiFetch(endpoints.createUser, {
            email: this.newAccountEmail,
            password: this.newAccountPassword,
            displayName: this.newAccountDisplayName,
            userId: this.$store.state.userId,
            idToken,
          })
            .then((response) => {
              updateUserLocal({
                managedAccounts: {
                  ...this.$store.state.currUser.managedAccounts,
                  [response.successfulResponse.newUserId]: {
                    userId: response.successfulResponse.newUserId,
                    dateCreated: Date.now(),
                    email: this.newAccountEmail,
                    password: this.newAccountPassword,
                    displayName: this.newAccountDisplayName,
                  },
                },
              });
              this.newAccountEmail = '';
              this.newAccountPassword = '';
              this.newAccountDisplayName = '';
              this.showAccountCreationDialog = false;
              showSuccess(t.accountSuccessfullyCreated);
              hideLoading(true);
            })
            .catch((error) => {
              hideLoading();
              const errorObject = error?.error;
              if (errorObject.code === 'auth/email-already-exists') {
                showError(t.emailAlreadyInUse);
              } else {
                showError(`Could not create the account.`, error, true);
              }
            });
        })
        .catch((error) => {
          hideLoading();
          showError(`Could not get user token to make request`, error, true);
        });
    },
    createMultipleAccounts() {
      interface NewUser extends ProfileMode {
        defaultPassword: string;
      }

      if (this.uploadedCreationCsvFile) {
        const reader = new FileReader();

        reader.onload = (event) => {
          let csvString = event.target?.result as string;

          if (!csvString) {
            showError(`No CSV provided`);
            return;
          }

          const currUser = fbAuth.currentUser;

          if (!currUser) {
            showError(`No logged in user`, null, true);
            return;
          }

          showLoading();

          parse(csvString, {
            header: true,
            transform: function (s: string) {
              return s.trim();
            },
            skipEmptyLines: true,
            complete: (results: any) => {
              let usersToCreate = results.data.filter((newUser: NewUser) => {
                return newUser.defaultPassword && newUser.email;
              });

              if (usersToCreate.length === 0) {
                showError(t.csvIsMalformed);
                hideLoading();
                return;
              }

              currUser
                .getIdToken(/* forceRefresh */ true)
                .then((idToken) => {
                  standardApiFetch(endpoints.createUserMultipleUsersWithProfileMode, {
                    usersToCreate,
                    userId: this.$store.state.userId,
                    idToken,
                  })
                    .then(async (response) => {
                      updateUserLocal({
                        managedAccounts: {
                          ...this.$store.state.currUser.managedAccounts,
                          ...response.successfulResponse.managedAccountsUpdate,
                        },
                      });

                      if (usersToCreate.length !== results.data.length) {
                        showError(t.someEntriesWereSkipped);
                      }

                      this.showAccountCreationFromCsvDialog = false;
                      await this.assignTeamWrapperToAllTeams();

                      if (response.successfulResponse.emailsThatWereInvalidForUserCreation.length) {
                        showConfirmation(t.accountsSuccessfullyCreatedButSomeFailed.supplant([response.successfulResponse.emailsThatWereInvalidForUserCreation.join(',')]));
                      } else {
                        showConfirmation(t.accountsSuccessfullyCreated);
                      }

                      hideLoading(true);
                    })
                    .catch((error) => {
                      hideLoading();
                      showError(`Could not create the accounts.`, error, true);
                    });
                })
                .catch((error) => {
                  hideLoading();
                  showError(`Could not get user token to make request`, error, true);
                });
            },
          });
        };
        reader.readAsText(this.uploadedCreationCsvFile);
      }
    },
    refreshAccountTable() {
      // It's a hack. I hate it but I'm out of ideas for this one. The data table does not update correctly unless we add a delay here.
      this.dataTableToggle = false;
      setTimeout(() => {
        this.dataTableToggle = true;
      }, 100);
    },
    assignMultipleAccounts() {
      this.showAssignAccountsFromCsvDialog = false;
      const whiteListedEmailsAfterBatchUpdate: { [key: string]: string[] } = {};

      interface AssignmentData {
        email: string;
        genericIdToAssign: string;
        teamIdToAssign: string;
      }

      if (this.uploadedAssignmentCsvFile) {
        const reader = new FileReader();

        reader.onload = (event) => {
          let csvString = event.target?.result as string;

          if (!csvString) {
            showError(`No CSV provided`);
            return;
          }

          const currUser = fbAuth.currentUser;

          if (!currUser) {
            showError(`No logged in user`, null, true);
            return;
          }

          showLoading();

          parse(csvString, {
            header: true,
            transform: function (s: string) {
              return s.trim();
            },
            skipEmptyLines: true,
            complete: (results: any) => {
              let assignments: AssignmentData[] = results.data.filter((assignment: AssignmentData) => {
                return assignment.email && (assignment.genericIdToAssign || assignment.teamIdToAssign);
              });

              if (assignments.length === 0) {
                showError(t.csvIsMalformed);
                hideLoading();
                return;
              }

              const promises: Promise<any>[] = [];
              const innerPromises: Promise<any>[] = [];

              assignments.forEach((assignment) => {
                const userId = this.managedAccounts.find((account) => account.email === assignment.email)?.userId;
                if (userId) {
                  if (assignment.genericIdToAssign) {
                    const promise = standardApiFetch(endpoints.activateSitchLink, {
                      activationId: assignment.genericIdToAssign,
                      userId,
                    });
                    promises.push(promise);
                  }
                  if (assignment.teamIdToAssign) {
                    const team = this.teams[assignment.teamIdToAssign];

                    const userDocForManagedUser = doc(currFirestore, 'users', userId);

                    const promise = getDoc(userDocForManagedUser)
                      .then((docSnap) => {
                        if (docSnap.exists()) {
                          const managedUser = docSnap.data() as PlatformUser;
                          whiteListedEmailsAfterBatchUpdate[team.docId] = whiteListedEmailsAfterBatchUpdate[team.docId] || this.teams[team.docId].whiteListedEmails;
                          whiteListedEmailsAfterBatchUpdate[team.docId].push(managedUser.email);
                          innerPromises.push(this.addManagedUserToSelectedTeam(team, managedUser, true));
                        }
                      })
                      .catch((error) => {
                        showError(`Could not add the user to the specified team.`, error, true);
                      });

                    promises.push(promise);
                  }
                } else {
                  showError(`The user with email: ${assignment.email}, is not a managed user.`);
                }
              });

              Promise.all(promises)
                .then(() => {
                  Promise.all(innerPromises)
                    .then(() => {
                      const batch = writeBatch(currFirestore);
                      Object.keys(whiteListedEmailsAfterBatchUpdate).forEach((teamId: string) => {
                        const newWhiteListedEmails = [...new Set(whiteListedEmailsAfterBatchUpdate[teamId])];
                        storeMapPropUpdate('createdTeams', teamId, {
                          whiteListedEmails: newWhiteListedEmails,
                        });
                        this.teams[teamId].whiteListedEmails = newWhiteListedEmails;
                        const teamDoc = doc(currFirestore, getUserDoc().path, 'teams', teamId);
                        // White list the new team member;
                        batch.update(teamDoc, {
                          whiteListedEmails: newWhiteListedEmails,
                        });
                      });
                      batch.commit().then(() => {
                        showSuccess(t.accountAssignmentsComplete);
                        hideLoading();
                      });
                    })
                    .catch((error) => {
                      showError(`Could not add users to the provided teams`, error, true);
                    });
                })
                .catch((error) => {
                  showError(`Could not complete assignments`, error, true);
                });
            },
          });
        };
        reader.readAsText(this.uploadedAssignmentCsvFile);
      }
    },
    loginAsUser() {
      if (!this.currentAccount) {
        return;
      }
      const currentAccount = this.currentAccount as ManagedAccount;
      signOutAccount().then(() => {
        signInWithEmailAndPassword(fbAuth, currentAccount.email, currentAccount.password)
          .then((userCredential) => {
            this.$router.push({ path: `/` });
            const user = userCredential.user;
            onUserSignIn(user);
          })
          .catch((error) => {
            showError(`Could not sign into the account.`, error, true);
          })
          .finally(() => {
            hideLoading();
          });
      });
    },
    inviteLink(team: Team): string {
      const baseUrl = isProductionEnv ? 'mysitch.app' : 'sitch-manager-test.web.app';
      return `${baseUrl}/?iv=${this.$store.state.userId}:${team.docId}`;
    },
    onEditToggle(team: Team) {
      this.onFormInteraction();
      const teamId: string = team.docId;
      if (!this.showContent[teamId]) {
        Object.keys(this.showContent).forEach((key) => (this.showContent[key] = false));
      }
      this.showContent[teamId] = !this.showContent[teamId];
      this.$forceUpdate();
    },
    shouldShowContent(team: Team): boolean {
      const teamId: string = team.docId;
      return Boolean(this.showContent[teamId]);
    },
    onAddAccountClick() {
      if (this.managedAccounts.length >= MANAGED_ACCOUNTS_MAX && !this.$store.state?.currUserModeGateway.hasPermanentPremium) {
        showError(t.cannotCreateMoreThanManagedAccounts.supplant([MANAGED_ACCOUNTS_MAX]));
        return;
      }
      this.showAccountCreationDialog = true;
    },
    onAssignAccountsFromCsvClick() {
      this.showAssignAccountsFromCsvDialog = true;
    },
    onAddAccountsFromCsvClick() {
      if (this.managedAccounts.length >= MANAGED_ACCOUNTS_MAX && !this.$store.state?.currUserModeGateway.hasPermanentPremium) {
        showError(t.cannotCreateMoreThanManagedAccounts.supplant([MANAGED_ACCOUNTS_MAX]));
        return;
      }
      this.showAccountCreationFromCsvDialog = true;
    },
    saveChanges() {
      if ((this.$refs.teamsForm as any).validate()) {
        if (Object.keys(this.teams).length >= TEAMS_MAX && !this.$store.state.currUserModeGateway?.hasPermanentPremium) {
          showError(t.cannotCreateMoreThanTeams.supplant([TEAMS_MAX]));
          return;
        }

        // Update modes.
        Object.values(this.teams).forEach((team) => {
          const teamModes: TeamMode[] = [];
          const deletedModeIds: string[] = [];
          team.modeIds = team.modeIds || [];
          team.modeIds.forEach((modeId) => {
            const currMode = (this.$store.state.modes as ModesMap)[modeId];
            if (currMode) {
              teamModes.push({
                docId: currMode.docId,
                linkId: currMode.linkId,
                name: currMode.name,
                displayName: currMode.displayName,
                type: currMode.type as eModeType,
              });
            } else {
              deletedModeIds.push(modeId);
            }
          });
          // Remove any deleted mode IDs.
          team.modeIds = team.modeIds.filter((id) => !deletedModeIds.includes(id));
          // Rebuild modes array with updated information.
          team.modes = teamModes;
        });

        const promises: Promise<any>[] = [];
        Object.values(this.teams).forEach((team) => {
          const newTeam: Team = {
            ...getEmptyTeam(),
            ...team,
            dateUpdated: Timestamp.fromMillis(Date.now()),
          };
          promises.push(setDoc(doc(currFirestore, getUserDoc().path, 'teams', team.docId), newTeam));
        });

        // Delete teams that are no longer used.
        this.deletedTeamsIds.forEach((teamToDeleteId) => {
          promises.push(deleteDoc(doc(currFirestore, getUserDoc().path, 'teams', teamToDeleteId)));
        });

        showLoading();

        Promise.all(promises)
          .then(() => {
            this.formIsDirty = false;
            this.$store.commit('createdTeams', this.teams);
            showSuccess(t.updateSuccessful);
          })
          .catch((error) => {
            showError(`Something went wrong when updating your teams:`, error, true);
          })
          .finally(() => {
            hideLoading();
          });
      } else {
        showError(t.thereAreErrorsInATeamForm);
      }
    },
    onDeleteTeam(teamToDelete: Team) {
      Vue.delete(this.teams, teamToDelete.docId);
      this.deletedTeamsIds.push(teamToDelete.docId);
    },
    onAddTeam() {
      if (Object.keys(this.teams).length >= TEAMS_MAX && !this.$store.state.currUserModeGateway?.hasPermanentPremium) {
        showError(t.cannotCreateMoreThanTeams.supplant([TEAMS_MAX]));
        return;
      }
      const newTeamId = generateId();
      const newTeam: Team = {
        ...getEmptyTeam(),
        docId: newTeamId,
        teamOwnerId: this.$store.state.userId,
      };
      this.teams[newTeamId] = newTeam;
      this.currPage = 1;
      this.$forceUpdate();
    },
    addEmail(email: string, team: Team) {
      if (!email) {
        return;
      }
      const isValid = validationRules.requiredEmailRules.every((rule) => {
        const result: boolean | string = rule(email);
        if (result === true) {
          return true;
        } else {
          showError(result as string);
          return false;
        }
      });
      if (isValid && !team.whiteListedEmails.includes(email)) {
        team.whiteListedEmails.push(email);
        this.currFullEmail = '';
        this.$forceUpdate();
      }
    },
    addEmailDomain(emailDomain: string, team: Team) {
      if (!emailDomain) {
        return;
      }
      const isValid = validationRules.requiredEmailDomainRules.every((rule) => {
        const result: boolean | string = rule(emailDomain);
        if (result === true) {
          return true;
        } else {
          showError(result as string);
          return false;
        }
      });
      if (isValid && !team.whiteListedEmailDomains.includes(emailDomain)) {
        this.currEmailDomain = '';
        team.whiteListedEmailDomains[0] = emailDomain;
        this.$forceUpdate();
      }
    },
    removeEmail(team: Team, email: string) {
      team.whiteListedEmails = team.whiteListedEmails.filter((currEmail: string) => currEmail !== email);
      this.$forceUpdate();
    },
    removeEmailDomain(team: Team, emailDomain: string) {
      team.whiteListedEmailDomains = team.whiteListedEmailDomains.filter((currEmailDomain: string) => currEmailDomain !== emailDomain);
      this.$forceUpdate();
    },
    getInviteEmail(team: Team, email: string) {
      const subject = `You've been invited to join ${team.name || 'a team'} on Sitch`;
      const body = `To link your account to this team on Sitch, sign in or sign up here: ${this.inviteLink(team)}.`;
      return `mailto:${email}?subject=${subject}&body=${encodeURIComponent(body)}`;
    },
    formatDate(date: number): string {
      return date ? format(date, 'MMM dd yyyy h:mm a') : t.notApplicable;
    },
    getModesThatCanBeWrappedForTeam(team: Team): TeamMode[] {
      return this.allModes.filter((mode) => {
        return team.modeIds.includes(mode.docId) && [eModeType.site, eModeType.group].includes(mode.type as eModeType);
      });
    },
    teamHasManagedAccounts(team: Team): boolean {
      const teamMemberIds = this.membersForTeam(team.docId).map((member) => member.userId);
      return this.managedAccounts.some((account) => teamMemberIds.includes(account.userId));
    },
    memberIsManaged(member: TeamMember): boolean {
      return this.managedAccounts.some((account) => account.userId === member.userId);
    },
    assignTeamWrapperToAllTeams() {
      const promises: Promise<any>[] = [];
      this.teamsArray().forEach((team) => {
        promises.push(this.assignTeamWrapper(team));
      });
      return Promise.all(promises)
        .catch((error) => {
          showError(`Could not update the wrappers:`, error);
        })
        .finally(() => {
          hideLoading();
          this.saveChanges();
        });
    },
    assignTeamWrapper(team: Team) {
      if (!team.wrapperModeIdToAssign) {
        showError('No wrapper ID.');
        return Promise.resolve();
      }
      const teamMembers = this.membersForTeam(team.docId);
      const linkId = team.modes.find((m) => m.docId === team.wrapperModeIdToAssign)?.linkId || '';
      const update: Partial<PublicUserModeGateway> = {
        wrapperModeId: team.wrapperModeIdToAssign,
        wrapperModeLinkId: linkId,
        wrapperModeOwnerId: team.teamOwnerId,
      };
      const promises: Promise<any>[] = [];
      teamMembers.forEach((member) => {
        if (this.memberIsManaged(member)) {
          promises.push(updateDoc(doc(currFirestore, 'publicUserModeGateways', member.userId), update));
        }
      });
      showLoading();
      return Promise.all(promises)
        .catch((error) => {
          showError(`Could not update the wrappers:`, error);
        })
        .finally(() => {
          hideLoading();
          this.saveChanges();
        });
    },
    assignThemeToTeam(team: Team) {
      // Just give the theme to team member gateways but also check to make sure it still exists
      if (!team.assignedThemeId) {
        showError('No theme ID.');
        return;
      }
      const teamMembers = this.membersForTeam(team.docId);
      const teamOwnerGatewayRef = doc(currFirestore, 'publicUserModeGateways', team.teamOwnerId);
      const update: Partial<PublicUserModeGateway> = {
        teamOwnerGatewayRef,
        assignedTeamThemeId: team.assignedThemeId,
        dateUpdated: Timestamp.fromMillis(Date.now()),
      };
      const promises: Promise<any>[] = [];
      teamMembers.forEach((member) => {
        if (this.memberIsManaged(member)) {
          promises.push(updateDoc(doc(currFirestore, 'publicUserModeGateways', member.userId), update));
        }
      });
      showLoading();
      Promise.all(promises)
        .catch((error) => {
          showError(`Could not update the themes for managed users on this team:`, error);
        })
        .finally(() => {
          hideLoading();
          this.saveChanges();
        });
    },
    updateAuthUser() {
      if ((this.$refs.managedUserForm as any).validate()) {
        if (!this.currentAccount) {
          return;
        }

        const user: User | null = fbAuth.currentUser;
        const managedUser = this.currentAccount;

        if (!user) {
          showError(`No user exists`, null, true);
          return;
        }

        const update: Partial<AuthUserUpdate> = {};

        if (this.newEmail && managedUser.email !== this.newEmail) {
          update.email = this.newEmail;
        }
        if (this.newPassword && managedUser.password !== this.newPassword) {
          update.password = this.newPassword;
        }
        if (this.newDisplayName && managedUser.displayName !== this.newDisplayName) {
          update.displayName = this.newDisplayName;
        }

        if (user) {
          user
            .getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              const authUserUpdate: AuthUserUpdate = {
                displayName: '',
                email: '',
                password: '',
                ...update,
                userId: this.$store.state.userId,
                managedUserId: managedUser.userId,
                wasCreatedBy: '',
                idToken,
              };

              standardApiFetch(endpoints.updateAuthUser, authUserUpdate).then(() => {
                showSnackBar(t?.updateSuccessful);
                updateUserLocal({
                  managedAccounts: {
                    ...this.$store.state.currUser.managedAccounts,
                    [managedUser.userId]: {
                      ...this.$store.state.currUser.managedAccounts[managedUser.userId],
                      email: update.email || this.currentAccount?.email,
                      password: update.password || this.currentAccount?.password,
                      displayName: update.displayName || this.currentAccount?.displayName,
                    },
                  },
                });
                this.showAccountActionsDialog = false;
              });
            })
            .catch((error) => {
              showError(`Could not get user token to make request`, error, true);
            });
        }
      }
    },
  },
});
